<template>
  <div>
    <el-row
      :gutter="20"
      justify="center"
      class="box-content"
    >
      <el-col
        :span="16"
        align
      >
        <el-row class="box-content-title">
          Policy Privacy
        </el-row>
        <el-row class="box-content-text">
          <b>Please read this document carefully to understand how we process and store your data in our systems.</b>
          <br>
          <div>
            <h3>1. Cloud Infrastructure</h3>
            <ul>
              <li>We use two unique projects on Google Cloud for each customer to allow for audits and validations</li>
              <li><b>Data project:</b> this project is owned by the client, you will have client owner permission, which will allow you to manage all aspects about your data, access and permissions for users as well as which project resources can access which types of data</li>
              <li><b>Execution project:</b> this project is owned and managed by the Magrathea team, you will have permission to view and export all logs generated by the project, which will allow you to audit the origin and destination of all your data</li>
            </ul>
            
            <h3>2. Personal Data</h3>
            <ul>
              <li>We store the e-mail used for authentication in the tools that you use only to identify the access in ours system, we do not use this information for any other purpose, including for communication.</li>
            </ul>
            
            <h3>3. Data Processing</h3>
            <ul>
              <li>When you add a data source and allow access to some service (Facebook Ads, Google Ads, Google Analytics, Bing Ads...), we only access data referring to the service in question, no other information about your account is collected and no action is taken. is performed (posting on social networks, changing settings...).</li>
              <li>The data obtained is only stored in our systems if you choose this option, if you only use our platform for processing exporting the data obtained to other external systems (Amazon S3, Amazon RDS, Google Storage, Google BigQuery...) no data is stored after charging is complete.</li>
              <li>Access to the data stored in our systems is restricted and only done with your explicit authorization, to analyze and correct possible system problems.</li>
              <li>The data processed in our systems is never shared with third parties.</li>
              <li>For authentication of integrations with data sources, we use OAuth whenever possible, which eliminates the need for you to enter your login data on our platform, when this method is not available, the information is stored encrypted in our databases.</li>
              <li>All communication between our systems is over SSL encrypted connections (HTTPS)</li>                
            </ul>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'PrivacyPolicy',
  
  components: {
    // CarouselSources,
    // TopicContent
    // MenuBar,
  },
};
</script>

<style scoped>
  .box-content {
    background: #ffffffd1;
  }
  .box-content img {
    height: 300px;
  }
  .box-content-title {
    font-size: 2em;
    color: #ff6700;
    padding: 10px;
    font-weight: bold;
  }
  .box-content-quoted {
    color: #ff6700;
    font-size: 2em;
    text-align: left;
  }
  .box-content-text {
    text-align: left;
    line-height: 30px;
    padding: 15px;
    color: #747474;
  }
  .box-content-text > h3 {
    width: 100%;
    text-align: left;
  }
  .el-divider--horizontal {
    border-top: 2px #ff6700 !important;
    background: #ff6700;
  }
</style>
